/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

export enum ApiAction {
  DeleteSingleCategoryDeviations = 'DeleteSingleCategoryDeviations',
  DeleteSubordinateCategoryDeviations = 'DeleteSubordinateCategoryDeviations',
  LoadConfigurationsFromCalculation = 'LoadConfigurationsFromCalculation',
}
export enum ApiOperationType {
  Create = 'Create',
  Replace = 'Replace',
  Patch = 'Patch',
  Delete = 'Delete',
  Read = 'Read',
}
export interface ApplicationConfigurationResponse {
  /** @format date */
  applicationDate: string | null;
  /** @format date */
  lastSaleTransactionDate: string | null;
  /** @format int32 */
  finishExecutionWithinDays: number;
  mdbProductIdAutoIncrement: string;
  mdbWarehouseIdAutoIncrement: string;
  isPickAndPackEnabled: boolean;
  displayProductId: DisplayEntityIdType;
  displayStoreId: DisplayEntityIdType;
}
export interface ApplicationConfigurationResponseDataResponse {
  messages?: string[] | null;
  data: ApplicationConfigurationResponse;
}
export interface ApplicationDataResponse {
  applicationDataType: string;
  isAvailable: boolean;
}
export interface ApplicationDataResponseCollectionResponse {
  messages?: string[] | null;
  data: ApplicationDataResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum ApplicationHintType {
  CategoryConfigurationDefinitionDescription = 'CategoryConfigurationDefinition.Description',
  CategoryConfigurationDefinitionTitle = 'CategoryConfigurationDefinition.Title',
}
export interface ApplicationSettings {
  type: ApplicationSettingsType;
  value: string;
}
export interface ApplicationSettingsCollectionResponse {
  messages?: string[] | null;
  data: ApplicationSettings[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum ApplicationSettingsType {
  FrontendVersion = 'FrontendVersion',
  FrontendStatus = 'FrontendStatus',
}
export enum ApplicationStatus {
  Closed = 'Closed',
  Opened = 'Opened',
}
export enum AttributeDefinitionType {
  DateOfFirstSale = 'DateOfFirstSale',
  DateOfLastSale = 'DateOfLastSale',
  DateOfFirstPurchase = 'DateOfFirstPurchase',
  DateOfLastPurchase = 'DateOfLastPurchase',
  AvailableSupply = 'AvailableSupply',
  StockPrice = 'StockPrice',
  SalePrice = 'SalePrice',
  NearestPromotionStartDate = 'NearestPromotionStartDate',
  NearestPromotionEndDate = 'NearestPromotionEndDate',
  OpenPurchaseOrdersQuantity = 'OpenPurchaseOrdersQuantity',
  Frequency6Month = 'Frequency6Month',
  Frequency12Month = 'Frequency12Month',
  Vat = 'Vat',
  SaleQuantityTotal = 'SaleQuantityTotal',
  SaleValueTotal = 'SaleValueTotal',
  MonthlyRevenueQuantity = 'MonthlyRevenueQuantity',
  MonthlyRevenueSalePriceValue = 'MonthlyRevenueSalePriceValue',
  MonthlyRevenueStockPriceValue = 'MonthlyRevenueStockPriceValue',
  AnnualRevenueQuantity = 'AnnualRevenueQuantity',
  AnnualRevenueStockPriceValue = 'AnnualRevenueStockPriceValue',
  AnnualRevenueSalePriceValue = 'AnnualRevenueSalePriceValue',
  AttributeValueId = 'AttributeValueId',
  CategoryId = 'CategoryId',
  ProductId = 'ProductId',
  RegionId = 'RegionId',
  WarehouseId = 'WarehouseId',
  SkuClassId = 'SkuClassId',
  MinimalQuantityForRedistribution = 'MinimalQuantityForRedistribution',
  MinimalSetForRedistribution = 'MinimalSetForRedistribution',
  IsEnabled = 'IsEnabled',
  UseAsSource = 'UseAsSource',
  UseAsTarget = 'UseAsTarget',
  IsForced = 'IsForced',
}
export interface BrandConfigurationResponse {
  /** @format int32 */
  id: number;
  name: string;
  /** @format int32 */
  productCount: number;
  isConfigured: boolean;
}
export interface BrandConfigurationResponseCollectionResponse {
  messages?: string[] | null;
  data: BrandConfigurationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface BrandConfigurationUpdateRequest {
  /** @format int32 */
  brandId: number;
}
export interface BrandDetailResponse {
  /** @format int32 */
  id: number;
  name: string;
  /** @format int32 */
  productCount: number;
}
export interface BrandDetailResponseCollectionResponse {
  messages?: string[] | null;
  data: BrandDetailResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface BrandResponse {
  /** @format int32 */
  id: number;
  name: string;
}
export enum CalculationConfigurationExperimentalType {
  ExperimentalConfigurationVersion = 'ExperimentalConfigurationVersion',
  HfmqIsEnabled = 'Hfmq_IsEnabled',
  HfmqMaximal = 'Hfmq_Maximal',
  HfmqMinPercentageOfTotalSupply = 'Hfmq_MinPercentageOfTotalSupply',
  HfmqPriceUpTo = 'Hfmq_PriceUpTo',
  SourceBufferIsEnabled = 'SourceBuffer_IsEnabled',
  SourceBufferSkuTypeDeadStockIsEnabled = 'SourceBuffer_SkuTypeDeadStockIsEnabled',
  SourceBufferSkuTypeSlowMoverIsEnabled = 'SourceBuffer_SkuTypeSlowMoverIsEnabled',
  SourceBufferSkuTypeFastMoverIsEnabled = 'SourceBuffer_SkuTypeFastMoverIsEnabled',
  SourceBufferOutlook = 'SourceBuffer_Outlook',
  SourceBufferSeasonalProtection = 'SourceBuffer_SeasonalProtection',
  TargetUrgentIsEnabled = 'TargetUrgent_IsEnabled',
  TargetUrgentOutlook = 'TargetUrgent_Outlook',
  SourceUrgentIsEnabled = 'SourceUrgent_IsEnabled',
  SourceUrgentSkuTypeDeadStockIsEnabled = 'SourceUrgent_SkuTypeDeadStockIsEnabled',
  SourceUrgentSkuTypeSlowMoverIsEnabled = 'SourceUrgent_SkuTypeSlowMoverIsEnabled',
  SourceUrgentSkuTypeFastMoverIsEnabled = 'SourceUrgent_SkuTypeFastMoverIsEnabled',
  SourceUrgentOutlook = 'SourceUrgent_Outlook',
  TargetBufferIsEnabled = 'TargetBuffer_IsEnabled',
  TargetBufferOutlook = 'TargetBuffer_Outlook',
  IsBalancingEnabled = 'IsBalancingEnabled',
  HfmqCeiling = 'HfmqCeiling',
  HfmqIntervalPriceUpTo = 'HfmqInterval_PriceUpTo',
  HfmqIntervalMinPercentageOfTotalSupply = 'HfmqInterval_MinPercentageOfTotalSupply',
  UseSourceBufferForFastMovers = 'UseSourceBufferForFastMovers',
  AverageSourceNonBufferForecastBoostPercentage = 'AverageSourceNonBufferForecastBoostPercentage',
  SourceBufferForecastConfigurationMonthsOfSupply = 'SourceBufferForecastConfiguration_MonthsOfSupply',
  SourceBufferForecastConfigurationMaxMonthsUseAsBuffer = 'SourceBufferForecastConfiguration_MaxMonthsUseAsBuffer',
  TargetBufferForecastConfigurationMonthsToSellout = 'TargetBufferForecastConfiguration_MonthsToSellout',
  TargetBufferForecastConfigurationMonthUseAsBuffer = 'TargetBufferForecastConfiguration_MonthUseAsBuffer',
}
export interface CalculationConfigurationRequest {
  productListsAllEnabled?: boolean | null;
  brandsAllEnabled?: boolean | null;
  regionFlowTypeId?: RegionFlowType | null;
  superTargetRedistributionTypeId?: SuperTargetRedistributionType | null;
  useClosingStores?: boolean | null;
  isExperimentConfigurationsEnabled?: boolean | null;
}
export interface CalculationConfigurationResponse {
  productListsAllEnabled: boolean;
  brandsAllEnabled: boolean;
  regionFlowTypeId: RegionFlowType;
  superTargetRedistributionTypeId: SuperTargetRedistributionType;
  useClosingStores: boolean;
  isExperimentConfigurationsEnabled: boolean;
}
export interface CalculationConfigurationResponseDataResponse {
  messages?: string[] | null;
  data: CalculationConfigurationResponse;
}
export interface CalculationDepartmentResponse {
  /** @format int64 */
  id: number;
  name: string;
  customerDepartmentId?: string | null;
  isEnabled: boolean;
  /** @format double */
  totalValue: number;
}
export interface CalculationDepartmentResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationDepartmentResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationDetailResponse {
  /** @format int32 */
  id: number;
  isDeletable: boolean;
  status: CalculationStatus;
  type: CalculationType;
  mode: CalculationMode;
  calculationFlags: CalculationFlags;
  title: string;
  description?: string | null;
  /** @format date-time */
  created: string;
  /** @format date */
  applicationDate: string;
  identityUserId?: string | null;
  /** @format int64 */
  rootCategoryId: number;
  /** @format date */
  finishExecutionUntil?: string | null;
  /** @format date */
  executionRateLastEstimatedOn?: string | null;
  isPap: boolean;
  rootCategoryRedistributions: CategoryRedistributionResponse[];
  owner?: UserResponse | null;
  sourceTemplate?: TemplateResponse | null;
  overlappingCalculations?: CalculationWithOverviewResponse[] | null;
  subordinateCalculations?: CalculationWithOverviewResponse[] | null;
  /** @format date */
  forecastDate: string;
  hasSkuAttributes: boolean;
  hasSkuForecasts: boolean;
  papDbSyncStatus: PapSyncStatus;
  papResultCalculationProgressStatus: PapProgressStatus;
  /** @format date-time */
  lastSyncedIntoPapAppAt?: string | null;
  configuration?: CalculationConfigurationResponse | null;
  /** @deprecated */
  productListsAllEnabled: boolean;
  files: CalculationFileResponse[];
  superiorCalculation?: CalculationResponse | null;
}
export interface CalculationDetailResponseDataResponse {
  messages?: string[] | null;
  data: CalculationDetailResponse;
}
export interface CalculationEvaluationRatesSummaryResponse {
  /** @format date */
  executionRateLastEstimatedOn?: string | null;
  /** @format date */
  finishExecutionUntil?: string | null;
  /** @format date-time */
  calculationCreatedAt: string;
  evaluationRatesSummary: RoutesEvaluationRatesResponse;
}
export interface CalculationEvaluationRatesSummaryResponseDataResponse {
  messages?: string[] | null;
  data: CalculationEvaluationRatesSummaryResponse;
}
export interface CalculationExperimentalConfigurationRequest {
  key: CalculationConfigurationExperimentalType;
  /** @format int32 */
  itemSequence: number;
  value: string;
}
export interface CalculationExperimentalConfigurationResponse {
  key: CalculationConfigurationExperimentalType;
  /** @format int32 */
  itemSequence: number;
  value: string;
}
export interface CalculationExperimentalConfigurationResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationExperimentalConfigurationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationFileResponse {
  /** @format int32 */
  id: number;
  calculationFileType: CalculationFileType;
  dataRetrievalStatus: DataRetrievalStatus;
  url: string;
  fileName: string;
}
export interface CalculationFileResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationFileResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum CalculationFileType {
  PairingXlsx = 'PairingXlsx',
  PairingSpecific = 'PairingSpecific',
}
export enum CalculationFlag {
  Initial = 'Initial',
  PreComputePotentialFinished = 'PreComputePotentialFinished',
  PreComputePotentialCrashed = 'PreComputePotentialCrashed',
  ImportSkuPotentialFinished = 'ImportSkuPotentialFinished',
  ImportSkuPotentialCrashed = 'ImportSkuPotentialCrashed',
  PreComputePotentialRequested = 'PreComputePotentialRequested',
}
export interface CalculationFlags {
  preComputePotentialFinished: boolean;
  preComputePotentialCrashed: boolean;
  importSkuPotentialFinished: boolean;
  importSkuPotentialCrashed: boolean;
}
export enum CalculationMode {
  Computed = 'Computed',
  Manual = 'Manual',
  Merged = 'Merged',
}
export interface CalculationPickingInsightsSummaryResponse {
  /** @format int32 */
  processedSkuCount: number;
  /** @format int32 */
  pickingTime: number;
  /** @format int32 */
  trimmedPickingTime: number;
  /** @format int32 */
  executedQuantity: number;
  /** @format double */
  executedValue: number;
}
export interface CalculationPickingInsightsSummaryResponseDataResponse {
  messages?: string[] | null;
  data: CalculationPickingInsightsSummaryResponse;
}
/** Picking issues for a store. */
export interface CalculationPickingIssuesResponse {
  store: StoreResponse;
  issues: PickingIssue[];
  /** @format int32 */
  count: number;
}
export interface CalculationPickingIssuesResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationPickingIssuesResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
/** Summary picking results for a calculation. */
export interface CalculationPickingResponse {
  /** @format date */
  finishExecutionUntil?: string | null;
  /** @format date-time */
  calculationCreatedAt: string;
  pickingStatus: PapProgressStatus;
  pickingResults: RoutesEvaluationRatesResponse;
}
export interface CalculationPickingResponseDataResponse {
  messages?: string[] | null;
  /** Summary picking results for a calculation. */
  data: CalculationPickingResponse;
}
export interface CalculationRequest {
  title?: string | null;
  description?: string | null;
  calculationType: CalculationType;
  calculationMode: CalculationMode;
  /** @format int32 */
  templateId?: number | null;
  overlappingCalculations?: number[] | null;
  subordinateCalculations?: CalculationRequest[] | null;
}
export interface CalculationResponse {
  /** @format int32 */
  id: number;
  isDeletable: boolean;
  status: CalculationStatus;
  type: CalculationType;
  mode: CalculationMode;
  calculationFlags: CalculationFlags;
  title: string;
  description?: string | null;
  /** @format date-time */
  created: string;
  /** @format date */
  applicationDate: string;
  identityUserId?: string | null;
  /** @format int64 */
  rootCategoryId: number;
  /** @format date */
  finishExecutionUntil?: string | null;
  /** @format date */
  executionRateLastEstimatedOn?: string | null;
  isPap: boolean;
}
export interface CalculationResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationSkuResponse {
  /** @format int32 */
  skuId: number;
  skuType: SkuType;
  isForced: boolean;
  isClosing: boolean;
  /** @format int32 */
  outgoingQuantity: number;
  /** @format int32 */
  incomingQuantity: number;
  targetList?: TargetListSkuResponse | null;
  skuClass?: SkuClassResponse | null;
  categories: CategoryResponse[];
  fieldConfigurations?: SkuFieldConfigurationResponse[] | null;
  sourceMinLayerLists?: EntityListSkuResponse[] | null;
  targetMinLayerLists?: EntityListSkuResponse[] | null;
  forecast?: SkuForecastResponse | null;
}
export interface CalculationSkuResponseDataResponse {
  messages?: string[] | null;
  data: CalculationSkuResponse;
}
export enum CalculationStatisticClusterType {
  Store = 'Store',
  StoreDepartment = 'StoreDepartment',
}
export interface CalculationStatisticRegionResponse {
  region: RegionResponse;
  /** @format double */
  valueSource: number;
  /** @format int32 */
  quantitySource: number;
  /** @format double */
  valueTarget: number;
  /** @format int32 */
  quantityTarget: number;
}
export interface CalculationStatisticRegionResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationStatisticRegionResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationStatisticRouteResponse {
  department?: DepartmentResponse | null;
  sourceStore: StoreResponse;
  targetStore: StoreResponse;
  /** @format double */
  value: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  pickingPositionCount: number;
  /** @format double */
  filledStockoutValue: number;
}
export interface CalculationStatisticRouteResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationStatisticRouteResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationStatisticRouteSummaryResponse {
  type: StatisticType;
  /** @format int32 */
  quantity: number;
  /** @format double */
  value: number;
  /** @format int32 */
  pickingPositionCount: number;
}
export interface CalculationStatisticRouteSummaryResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationStatisticRouteSummaryResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationStatisticStoreSourceResponse {
  sourceStore: StoreResponse;
  department?: DepartmentResponse | null;
  /** @format double */
  value: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  productCount: number;
  /** @format int32 */
  targetStoreCount: number;
  /** @format int32 */
  pickingPositionCount: number;
}
export interface CalculationStatisticStoreSourceResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationStatisticStoreSourceResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationStatisticStoreSourceSummaryResponse {
  type: StatisticType;
  /** @format int32 */
  quantity: number;
  /** @format double */
  value: number;
  /** @format int32 */
  productCount: number;
  /** @format int32 */
  targetStoreCount: number;
  /** @format int32 */
  pickingPositionCount: number;
}
export interface CalculationStatisticStoreSummaryResponse {
  sourceSummaries: CalculationStatisticStoreSourceSummaryResponse[];
  targetSummaries: CalculationStatisticStoreTargetSummaryResponse[];
}
export interface CalculationStatisticStoreSummaryResponseDataResponse {
  messages?: string[] | null;
  data: CalculationStatisticStoreSummaryResponse;
}
export interface CalculationStatisticStoreTargetResponse {
  targetStore: StoreResponse;
  department?: DepartmentResponse | null;
  /** @format double */
  value: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  productCount: number;
  /** @format int32 */
  sourceStoreCount: number;
  /** @format int32 */
  pickingPositionCount: number;
  /** @format double */
  filledStockoutValue: number;
}
export interface CalculationStatisticStoreTargetResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationStatisticStoreTargetResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationStatisticStoreTargetSummaryResponse {
  type: StatisticType;
  /** @format int32 */
  quantity: number;
  /** @format double */
  value: number;
  /** @format int32 */
  productCount: number;
  /** @format int32 */
  sourceStoreCount: number;
  /** @format int32 */
  pickingPositionCount: number;
}
export interface CalculationStatisticSummaryResponse {
  /** @format double */
  value: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  productCount: number;
  /** @format int32 */
  pickingPositionCount: number;
  /** @format int32 */
  storesSourceAvailableCount: number;
  /** @format int32 */
  storesSourceUsedCount: number;
  /** @format int32 */
  storesTargetAvailableCount: number;
  /** @format int32 */
  storesTargetUsedCount: number;
  /** @format double */
  filledStockoutValue: number;
  /** @format double */
  targetMinimalLayerValue: number;
  /** @format double */
  sourceForcedProductValue: number;
  /** @format double */
  sourceClosingProductValue: number;
  /** @format int32 */
  routesCount: number;
  /** @format int32 */
  avgOutgoingRoutes: number;
  /** @format int32 */
  avgIncomingRoutes: number;
}
export interface CalculationStatisticSummaryResponseDataResponse {
  messages?: string[] | null;
  data: CalculationStatisticSummaryResponse;
}
export interface CalculationStatisticTargetListItemResponse {
  /** @format int32 */
  skuId: number;
  product: ProductResponse;
  store: StoreResponse;
  department: DepartmentResponse;
  /** @format int32 */
  originalRequestedQuantity: number;
  /** @format int32 */
  receivedQuantity: number;
  /** @format int32 */
  requestedQuantity: number;
  /** @format int32 */
  quantityNotReceived: number;
  /** @format int32 */
  availableSupply: number;
  /** @format int32 */
  openPurchaseOrdersQuantity: number;
  /** @format double */
  stockPrice: number;
  isEligible: boolean;
}
export interface CalculationStatisticTargetListItemResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationStatisticTargetListItemResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationStatisticTargetListItemSourceResponse {
  /** @format int32 */
  skuId: number;
  store: StoreResponse;
  department: DepartmentResponse;
  /** @format double */
  stockPrice: number;
  /** @format int32 */
  availableSupply: number;
  isForcedSkuAttribute: boolean;
  useAsSource: boolean;
  /** @format int32 */
  totalSupply: number;
  /** @format int32 */
  incomingQuantity: number;
  /** @format int32 */
  outgoingQuantity: number;
  /** @format int32 */
  availableToSend: number;
  /** @format int32 */
  sentQuantity: number;
  /** @format int32 */
  blockedByAllSettings: number;
  /** @format int32 */
  blockedByCategory: number;
  /** @format int32 */
  blockedByFirstPurchaseDate: number;
  /** @format int32 */
  blockedByLastPurchaseDate: number;
  /** @format int32 */
  blockedByMinLayers: number;
  /** @format int32 */
  blockedByMinValue: number;
  /** @format int32 */
  blockedByPromo: number;
  /** @format int32 */
  blockedByForecast: number;
}
export interface CalculationStatisticTargetListItemSourceResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationStatisticTargetListItemSourceResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationStatisticTargetListSummaryResponse {
  /** @format int32 */
  receivedQuantity: number;
  /** @format int32 */
  requestedQuantity: number;
  /** @format int32 */
  quantityNotReceived: number;
}
export interface CalculationStatisticTargetListSummaryResponseDataResponse {
  messages?: string[] | null;
  data: CalculationStatisticTargetListSummaryResponse;
}
export enum CalculationStatus {
  Pending = 'Pending',
  Queued = 'Queued',
  Running = 'Running',
  Completed = 'Completed',
  Crashed = 'Crashed',
  SoftDeleted = 'SoftDeleted',
  ToBeQueued = 'ToBeQueued',
  Outdated = 'Outdated',
}
export enum CalculationType {
  Template = 'Template',
  Private = 'Private',
  Simulation = 'Simulation',
  Production = 'Production',
  Submerged = 'Submerged',
}
export interface CalculationUpdateRequest {
  title?: string | null;
  description?: string | null;
  calculationType?: CalculationType | null;
  /** @format date */
  finishExecutionUntil?: string | null;
  isPap?: boolean | null;
}
export interface CalculationWithOverviewResponse {
  /** @format int32 */
  id: number;
  isDeletable: boolean;
  status: CalculationStatus;
  type: CalculationType;
  mode: CalculationMode;
  calculationFlags: CalculationFlags;
  title: string;
  description?: string | null;
  /** @format date-time */
  created: string;
  /** @format date */
  applicationDate: string;
  identityUserId?: string | null;
  /** @format int64 */
  rootCategoryId: number;
  /** @format date */
  finishExecutionUntil?: string | null;
  /** @format date */
  executionRateLastEstimatedOn?: string | null;
  isPap: boolean;
  rootCategoryRedistributions: CategoryRedistributionResponse[];
}
export interface CalculationWithOverviewResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationWithOverviewResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CalculationWithRelatedEntitiesResponse {
  /** @format int32 */
  id: number;
  isDeletable: boolean;
  status: CalculationStatus;
  type: CalculationType;
  mode: CalculationMode;
  calculationFlags: CalculationFlags;
  title: string;
  description?: string | null;
  /** @format date-time */
  created: string;
  /** @format date */
  applicationDate: string;
  identityUserId?: string | null;
  /** @format int64 */
  rootCategoryId: number;
  /** @format date */
  finishExecutionUntil?: string | null;
  /** @format date */
  executionRateLastEstimatedOn?: string | null;
  isPap: boolean;
  rootCategoryRedistributions: CategoryRedistributionResponse[];
  owner?: UserResponse | null;
  sourceTemplate?: TemplateResponse | null;
  overlappingCalculations?: CalculationWithOverviewResponse[] | null;
  subordinateCalculations?: CalculationWithOverviewResponse[] | null;
}
export interface CalculationWithRelatedEntitiesResponseCollectionResponse {
  messages?: string[] | null;
  data: CalculationWithRelatedEntitiesResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CategoryAttributeValueResponse {
  categoryAttributeValueTypeId: CategoryAttributeValueType;
  /** @format double */
  value: number;
}
export enum CategoryAttributeValueType {
  TotalSkuCount = 'TotalSkuCount',
  EnabledSkuCount = 'EnabledSkuCount',
  AvailableSupplyValue = 'AvailableSupplyValue',
  MonthlyRevenueSalePriceValue = 'MonthlyRevenueSalePriceValue',
  AnnualRevenueSalePriceValue = 'AnnualRevenueSalePriceValue',
  Margin = 'Margin',
}
export interface CategoryConfigurationDefinitionResponse {
  /** @format int32 */
  id: number;
  sectionType: ConfigurationSectionType;
  fieldType: ConfigurationFieldType;
  dataType: ConfigurationDataType;
  title: string;
  description?: string | null;
  /** @format int32 */
  position: number;
}
export interface CategoryConfigurationDefinitionResponseCollectionResponse {
  messages?: string[] | null;
  data: CategoryConfigurationDefinitionResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CategoryEvaluationRatesResponse {
  category: CategorySkuCountResponse;
  evaluationSummary: EvaluationRatesResponse;
}
export interface CategoryEvaluationRatesResponseCollectionResponse {
  messages?: string[] | null;
  data: CategoryEvaluationRatesResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CategoryFieldConfigurationResponse {
  skuType?: SkuType | null;
  fieldType: ConfigurationFieldType;
  value: string;
  /** @format int64 */
  categoryId: number;
}
export interface CategoryFieldConfigurationResponseCollectionResponse {
  messages?: string[] | null;
  data: CategoryFieldConfigurationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CategoryInsightsResponse {
  summaries: CategorySummaryResponse[];
  categoryRedistributions?: CategoryRedistributionResponse[] | null;
  attributeValues: CategoryAttributeValueResponse[];
}
export interface CategoryInsightsResponseDataResponse {
  messages?: string[] | null;
  data: CategoryInsightsResponse;
}
export interface CategoryRedistributionResponse {
  skuTypeId: SkuType;
  categoryRedistributionTypeId: CategoryRedistributionType;
  /** @format double */
  value: number;
}
export enum CategoryRedistributionType {
  SourceStandardValue = 'SourceStandardValue',
  SourceForcedValue = 'SourceForcedValue',
  SourceIsClosingValue = 'SourceIsClosingValue',
  TargetForecastValue = 'TargetForecastValue',
  TargetMinLayersValue = 'TargetMinLayersValue',
  TargetTargetListValue = 'TargetTargetListValue',
  TargetOthersValue = 'TargetOthersValue',
  SendToStockoutValue = 'SendToStockoutValue',
}
export interface CategoryResponse {
  /** @format int64 */
  id: number;
  /** @format int64 */
  parentCategoryId?: number | null;
  /** @format int32 */
  level: number;
  title: string;
  code?: string | null;
  customerCategoryId?: string | null;
}
export interface CategoryResponseCollectionResponse {
  messages?: string[] | null;
  data: CategoryResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CategorySkuCountResponse {
  /** @format int64 */
  id: number;
  /** @format int64 */
  parentCategoryId?: number | null;
  /** @format int32 */
  level: number;
  title: string;
  code?: string | null;
  customerCategoryId?: string | null;
  /** @format int32 */
  skuCount: number;
  /** @format int32 */
  enabledSkuCount: number;
}
export interface CategorySkuCountResponseCollectionResponse {
  messages?: string[] | null;
  data: CategorySkuCountResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface CategorySummaryResponse {
  skuTypeId: SkuType;
  categorySummaryTypeId: CategorySummaryType;
  /** @format double */
  value: number;
}
export enum CategorySummaryType {
  EligibleAvailableSupplyValue = 'EligibleAvailableSupplyValue',
  EligibleSourceSkuCount = 'EligibleSourceSkuCount',
  EligibleSourceSupplyValue = 'EligibleSourceSupplyValue',
  EligibleTargetSkuCount = 'EligibleTargetSkuCount',
  TargetSupplyForTheoreticalPotentialValue = 'TargetSupplyForTheoreticalPotentialValue',
  OpenPurchaseOrdersForTheoreticalPotentialValue = 'OpenPurchaseOrdersForTheoreticalPotentialValue',
  IncomingForTheoreticalPotentialValue = 'IncomingForTheoreticalPotentialValue',
  EligibleMonthlyRevenue = 'EligibleMonthlyRevenue',
  EligibleMargin = 'EligibleMargin',
  ForcedValue = 'ForcedValue',
  IsClosingValue = 'IsClosingValue',
  HealthyValue = 'HealthyValue',
  OutgoingValue = 'OutgoingValue',
  ExcessiveBlockedByMinLayersValue = 'ExcessiveBlockedByMinLayersValue',
  ExcessiveBlockedByFirstPurchaseDateValue = 'ExcessiveBlockedByFirstPurchaseDateValue',
  ExcessiveBlockedByLastPurchaseDateValue = 'ExcessiveBlockedByLastPurchaseDateValue',
  ExcessiveBlockedByMinValue = 'ExcessiveBlockedByMinValue',
  ExcessiveBlockedByPromoValue = 'ExcessiveBlockedByPromoValue',
  ExcessiveBlockedByForecastValue = 'ExcessiveBlockedByForecastValue',
  ExcessiveBlockedByMinimalQuantityValue = 'ExcessiveBlockedByMinimalQuantityValue',
  ExcessiveBlockedByMinimalSetValue = 'ExcessiveBlockedByMinimalSetValue',
  ExcessivePotentialTotalValue = 'ExcessivePotentialTotalValue',
  ExcessivePotentialNotAddressableValue = 'ExcessivePotentialNotAddressableValue',
  TheoreticalPotentialValue = 'TheoreticalPotentialValue',
  TheoreticalPotentialStockoutValue = 'TheoreticalPotentialStockoutValue',
  TargetPotentialOverreachingSourceValue = 'TargetPotentialOverreachingSourceValue',
  TargetPotentialUnfulfilledWithAnySourceValue = 'TargetPotentialUnfulfilledWithAnySourceValue',
  TargetPotentialEndorsedByForecastValue = 'TargetPotentialEndorsedByForecastValue',
  TargetPotentialEndorsedByMinimalLayersValue = 'TargetPotentialEndorsedByMinimalLayersValue',
  TargetPotentialEndorsedByTargetListsValue = 'TargetPotentialEndorsedByTargetListsValue',
}
export interface CategoryWarehouseOverviewItemResponse {
  overviewValueTypeId: OverviewValueType;
  categoryWarehouseOverviewTypeId: CategoryWarehouseOverviewType;
  /** @format double */
  value?: number | null;
}
export interface CategoryWarehouseOverviewResponse {
  warehouse: WarehouseResponse;
  overviews: CategoryWarehouseOverviewItemResponse[];
}
export interface CategoryWarehouseOverviewResponseCollectionResponse {
  messages?: string[] | null;
  data: CategoryWarehouseOverviewResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum CategoryWarehouseOverviewType {
  TotalSupply = 'TotalSupply',
  AvgMonthlySale = 'AvgMonthlySale',
  AvgMonthlySaleForStockout = 'AvgMonthlySaleForStockout',
  Margin = 'Margin',
  SkuCount = 'SkuCount',
  SkuCountInStockout = 'SkuCountInStockout',
  TotalPotentialSource = 'TotalPotentialSource',
  TotalPotentialTarget = 'TotalPotentialTarget',
}
export enum CompareType {
  Missing = 'Missing',
  Contains = 'Contains',
  Updated = 'Updated',
}
export interface Condition {
  fieldName?: string;
  operation?: Operation | null;
  value?: any;
  rawQuery?: string | null;
  logicalOperator?: LogicalOperator | null;
}
export enum ConfigurationDataType {
  InputNumber = 'InputNumber',
  CheckBox = 'CheckBox',
  SelectBoxForecastConfidence = 'SelectBoxForecastConfidence',
  SelectBoxMonthIdentifySkuType = 'SelectBoxMonthIdentifySkuType',
  SelectBoxMonthsForForecast = 'SelectBoxMonthsForForecast',
  SelectBoxForcingLevelType = 'SelectBoxForcingLevelType',
  SelectBoxMinLayerList = 'SelectBoxMinLayerList',
  SelectBoxTargetList = 'SelectBoxTargetList',
}
export enum ConfigurationFieldType {
  CatDeadStock = 'CatDeadStock',
  CatSlowMoverMonths = 'CatSlowMoverMonths',
  CatSlowMoverSales = 'CatSlowMoverSales',
  PsEnableWholeSection = 'PsEnableWholeSection',
  PsMonthsFromFirstPurchase = 'PsMonthsFromFirstPurchase',
  PsMonthsFromLastPurchase = 'PsMonthsFromLastPurchase',
  PsMinRowValue = 'PsMinRowValue',
  PsUseProductWithPromo = 'PsUseProductWithPromo',
  PsMonthsOfSupplyToKeep = 'PsMonthsOfSupplyToKeep',
  PsMaxForecastConfidence = 'PsMaxForecastConfidence',
  PtMonthsOfSupplyToGetByForecast = 'PtMonthsOfSupplyToGetByForecast',
  PtMinForecastConfidence = 'PtMinForecastConfidence',
  PtMinimal6MonthsFrequencyOfSales = 'PtMinimal6MonthsFrequencyOfSales',
  PtAddOpenPurchaseOrdersToAvailableSupply = 'PtAddOpenPurchaseOrdersToAvailableSupply',
  PtTargetStockoutsOnly = 'PtTargetStockoutsOnly',
  PsUseForcedRedistribution = 'PsUseForcedRedistribution',
  PtForcingLevelTypeId = 'PtForcingLevelTypeId',
  PsMinLayerList = 'PsMinLayerList',
  PtMinLayerList = 'PtMinLayerList',
  PtTargetList = 'PtTargetList',
}
export interface ConfigurationRequest {
  status: ConfigurationStatus;
}
export interface ConfigurationResponse {
  templateStatus: CalculationStatus;
  applicationStatus: ApplicationStatus;
  statusInfo?: string | null;
  /** @format int64 */
  rootCategoryId: number;
}
export interface ConfigurationResponseDataResponse {
  messages?: string[] | null;
  data: ConfigurationResponse;
}
export interface ConfigurationRuleCreateRequest {
  name?: string | null;
  scopes: ConfigurationRuleScopeRequest[];
  setupSet: ConfigurationRuleSetupRequest[];
}
export interface ConfigurationRuleReplaceRequest {
  name?: string | null;
  scopes: ConfigurationRuleScopeRequest[];
  setupSet: ConfigurationRuleSetupRequest[];
}
export interface ConfigurationRuleResponse {
  /** @format int32 */
  id: number;
  /** @format int32 */
  priority: number;
  name?: string | null;
  scopes: ConfigurationRuleScopeResponse[];
  setupSet: ConfigurationRuleSetupResponse[];
}
export interface ConfigurationRuleResponseCollectionResponse {
  messages?: string[] | null;
  data: ConfigurationRuleResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface ConfigurationRuleResponseDataResponse {
  messages?: string[] | null;
  data: ConfigurationRuleResponse;
}
export enum ConfigurationRuleScopeEntityType {
  Regions = 'Regions',
  Stores = 'Stores',
  StoreTypes = 'StoreTypes',
  Departments = 'Departments',
}
export enum ConfigurationRuleScopeMode {
  All = 'All',
  Specified = 'Specified',
  AllExcept = 'AllExcept',
}
export interface ConfigurationRuleScopeRequest {
  entityType: ConfigurationRuleScopeEntityType;
  scopeMode: ConfigurationRuleScopeMode;
  entityIds: number[];
}
export interface ConfigurationRuleScopeResponse {
  entityType: ConfigurationRuleScopeEntityType;
  scopeMode: ConfigurationRuleScopeMode;
  entityIds: number[];
}
export interface ConfigurationRuleSetupCreateRequest {
  setupType: ConfigurationRuleSetupType;
  value: string;
}
export interface ConfigurationRuleSetupDeleteRequest {
  setupType: ConfigurationRuleSetupType;
  value: string;
}
export interface ConfigurationRuleSetupRequest {
  setupType: ConfigurationRuleSetupType;
  value: string;
}
export interface ConfigurationRuleSetupResponse {
  setupType: ConfigurationRuleSetupType;
  value: string;
}
export enum ConfigurationRuleSetupType {
  IsEnabled = 'IsEnabled',
  UseAsSource = 'UseAsSource',
  UseAsTarget = 'UseAsTarget',
  MaxNumberOfSourceStores = 'MaxNumberOfSourceStores',
  MaxNumberOfTargetStores = 'MaxNumberOfTargetStores',
  MaxNumberOfSourcePickingPositions = 'MaxNumberOfSourcePickingPositions',
  MinimumRouteValue = 'MinimumRouteValue',
  IsSuperTarget = 'IsSuperTarget',
  IsClosing = 'IsClosing',
}
export interface ConfigurationRuleSetupUpdateRequest {
  setupType: ConfigurationRuleSetupType;
  value: string;
}
export interface ConfigurationRuleUpdateRequest {
  name?: string | null;
  /** @format int32 */
  priority?: number | null;
}
export enum ConfigurationSectionType {
  General = 'General',
  PotentialSource = 'PotentialSource',
  PotentialSourceStandardSubsection = 'PotentialSourceStandardSubsection',
  PotentialTarget = 'PotentialTarget',
}
export enum ConfigurationStatus {
  RefreshPotential = 'RefreshPotential',
}
export enum DataRetrievalStatus {
  Computing = 'Computing',
  Saving = 'Saving',
  Saved = 'Saved',
}
export interface DeleteRegionFlowGroupConfigurationRequest {
  regionIds: number[];
}
export interface DeleteRegionFlowGroupRequest {
  regionFlowGroupIds: number[];
}
export interface DepartmentResponse {
  /** @format int64 */
  id: number;
  name: string;
  customerDepartmentId?: string | null;
}
export interface DepartmentResponseCollectionResponse {
  messages?: string[] | null;
  data: DepartmentResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum DisplayEntityIdType {
  None = 'None',
  Code = 'Code',
  CustomerId = 'CustomerId',
}
export enum EntityIdentificationType {
  Code = 'Code',
  CustomerId = 'CustomerId',
}
export interface EntityListCreateRequest {
  entityListTypeId: EntityListType;
  /** @minLength 1 */
  name: string;
  description?: string | null;
}
export enum EntityListImportAction {
  NotSet = 'NotSet',
  Skip = 'Skip',
  Insert = 'Insert',
  Update = 'Update',
  CreateSkuAndInsert = 'CreateSkuAndInsert',
  Duplicate = 'Duplicate',
  NoChange = 'NoChange',
  Delete = 'Delete',
}
export interface EntityListImportItemResponse {
  /** @format int32 */
  sequence: number;
  productIdentification?: string | null;
  product?: ProductResponse | null;
  storeIdentification?: string | null;
  store?: StoreResponse | null;
  sku?: SkuBaseResponse | null;
  /** @format int32 */
  quantity?: number | null;
  entityListImportActionCode: EntityListImportAction;
}
export interface EntityListImportItemResponseCollectionResponse {
  messages?: string[] | null;
  data: EntityListImportItemResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface EntityListImportItemsRequest {
  /** @format date-time */
  verificationTimeStamp: string;
  importItemsEncodedBase64: string;
}
export interface EntityListImportRequest {
  /** @format date-time */
  verificationTimeStamp: string;
  storeIdentificationTypeId?: EntityIdentificationType | null;
  productIdentificationTypeId?: EntityIdentificationType | null;
  apiOperationType: ApiOperationType;
}
export interface EntityListImportResponse {
  storeIdentificationTypeId?: EntityIdentificationType | null;
  productIdentificationTypeId?: EntityIdentificationType | null;
  apiAction: ApiOperationType;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  verificationTimeStamp: string;
  user: UserResponse;
}
export interface EntityListImportResponseDataResponse {
  messages?: string[] | null;
  data: EntityListImportResponse;
}
export interface EntityListImportSummaryResponse {
  entityListImportAction: EntityListImportAction;
  /** @format int32 */
  itemCount: number;
}
export interface EntityListImportSummaryResponseCollectionResponse {
  messages?: string[] | null;
  data: EntityListImportSummaryResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface EntityListImportVerificationRequest {
  /** @format date-time */
  verificationTimeStamp: string;
}
export interface EntityListItemResponse {
  category?: CategoryResponse | null;
  product?: ProductResponse | null;
  store?: StoreResponse | null;
  department?: DepartmentResponse | null;
  sku?: SkuBaseResponse | null;
  /** @format int32 */
  quantity?: number | null;
}
export interface EntityListItemResponseCollectionResponse {
  messages?: string[] | null;
  data: EntityListItemResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface EntityListResponse {
  /** @format int32 */
  entityListId: number;
  /** @format int32 */
  lastEntityListVersionNumber: number;
  /** @format int32 */
  lastEntityListVersionId: number;
  entityListTypeId: EntityListType;
  entityListSourceTypeId: EntityListSourceType;
  customerEntityListId?: string | null;
  name?: string | null;
  description?: string | null;
  /** @format int32 */
  productCount: number;
  /** @format int32 */
  storeCount: number;
  /** @format int32 */
  departmentCount: number;
  /** @format int32 */
  storeDepartmentCount: number;
  /** @format int32 */
  skuCount: number;
  isArchived: boolean;
  /** @format date-time */
  createdAt: string;
  isDeletable: boolean;
  isArchivable: boolean;
  entityListImport?: EntityListImportResponse | null;
}
export interface EntityListResponseCollectionResponse {
  messages?: string[] | null;
  data: EntityListResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface EntityListResponseDataResponse {
  messages?: string[] | null;
  data: EntityListResponse;
}
export interface EntityListSkuResponse {
  /** @format int32 */
  entityListId: number;
  /** @format int32 */
  entityListVersionId: number;
  /** @format int32 */
  version: number;
  name?: string | null;
  /** @format int32 */
  quantity: number;
}
export interface EntityListSkuResponseCollectionResponse {
  messages?: string[] | null;
  data: EntityListSkuResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum EntityListSourceType {
  Integration = 'Integration',
  Computation = 'Computation',
  User = 'User',
}
export enum EntityListType {
  MinLayerList = 'MinLayerList',
  TargetList = 'TargetList',
  ProductList = 'ProductList',
}
export interface EntityListUpdateRequest {
  name?: string | null;
  description?: string | null;
  isArchived?: boolean | null;
}
export interface EvaluationRatesResponse {
  /** @format int32 */
  totalQuantity: number;
  /** @format int32 */
  executedQuantity: number;
  /** @format double */
  totalValue: number;
  /** @format double */
  executedValue: number;
  /** @format int32 */
  totalSkuCount: number;
  /** @format int32 */
  executedSkuCount: number;
}
export interface FieldConfigurationDeleteRequest {
  configurationFieldType: ConfigurationFieldType;
  skuType: SkuType | null;
}
export interface FieldConfigurationRequest {
  configurationFieldType: ConfigurationFieldType;
  skuType?: SkuType | null;
  value: string;
}
export interface ForecastMonthlyOverviewResponse {
  monthInterval: MonthIntervalResponse;
  overviews: ForecastValueResponse[];
}
export interface ForecastMonthlyOverviewResponseCollectionResponse {
  messages?: string[] | null;
  data: ForecastMonthlyOverviewResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface ForecastValueResponse {
  overviewValueType: OverviewValueType;
  transactionOverviewType: TransactionOverviewType;
  /** @format double */
  value: number;
  /** @format int32 */
  outlookMonth: number;
  /** @format int32 */
  confidence: number;
}
export interface HintResponse {
  applicationHintType: ApplicationHintType;
  key: string;
  value: string;
}
export interface HintResponseCollectionResponse {
  messages?: string[] | null;
  data: HintResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum IdentificationType {
  Code = 'Code',
  CustomerId = 'CustomerId',
}
export enum LogicalOperator {
  And = 'and',
  Or = 'or',
  Not = 'not',
}
export interface MonthIntervalResponse {
  /** @format int32 */
  month: number;
  /** @format int32 */
  year: number;
  /** @format date */
  dateFrom: string;
  /** @format date */
  dateTo: string;
}
export enum Operation {
  Eq = 'eq',
  Ne = 'ne',
  Gt = 'gt',
  Ge = 'ge',
  Lt = 'lt',
  Le = 'le',
  Contains = 'contains',
  Notcontains = 'notcontains',
  Startswith = 'startswith',
  Endswith = 'endswith',
  In = 'in',
  Notin = 'notin',
}
export enum OverviewValueType {
  Quantity = 'Quantity',
  Value = 'Value',
}
export enum PapProgressStatus {
  NotStarted = 'NotStarted',
  PickingStarted = 'PickingStarted',
  PickingDone = 'PickingDone',
  Finalized = 'Finalized',
}
export enum PapRedistributionIssue {
  SkipPermanently = 'SkipPermanently',
  SkipTemporarily = 'skipTemporarily',
  NotFound = 'NotFound',
  DifferentSupply = 'DifferentSupply',
  DamagedOrExpired = 'DamagedOrExpired',
  NotSending = 'NotSending',
  Other = 'Other',
  Damaged = 'Damaged',
  Expired = 'Expired',
  Sale = 'Sale',
  NotInPlanogram = 'NotInPlanogram',
}
export enum PapSyncStatus {
  NotSynced = 'NotSynced',
  Synced = 'Synced',
  Syncing = 'Syncing',
  Unsyncing = 'Unsyncing',
  SyncFailed = 'SyncFailed',
}
export interface PickingIssue {
  papRedistributionIssueId: PapRedistributionIssue;
  /** @format int32 */
  count: number;
}
export interface PickingIssueResponse {
  /** @format int32 */
  sourceSkuId: number;
  /** @format int32 */
  targetSkuId: number;
  targetStore: StoreResponse;
  papRedistributionIssueId: PapRedistributionIssue;
  product: ProductResponse;
  /** @format int32 */
  quantityExecuted: number;
  /** @format int32 */
  quantityRequested: number;
  sourceStore?: StoreResponse | null;
}
export interface PickingIssueResponseCollectionResponse {
  messages?: string[] | null;
  data: PickingIssueResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface PreComputePotentialTriggerRequest {
  projectCode: string;
  /** @format int32 */
  calculationId?: number | null;
  /** @format int32 */
  templateId?: number | null;
}
export enum ProductAttributeValueType {
  DateOfFirstSale = 'DateOfFirstSale',
  DateOfLastSale = 'DateOfLastSale',
  DateOfFirstPurchase = 'DateOfFirstPurchase',
  DateOfLastPurchase = 'DateOfLastPurchase',
  NearestPromotionStartDate = 'NearestPromotionStartDate',
  NearestPromotionEndDate = 'NearestPromotionEndDate',
  AvailableSupply = 'AvailableSupply',
  SaleQuantityTotal = 'SaleQuantityTotal',
  SaleValueTotal = 'SaleValueTotal',
  AvailableStockPriceValue = 'AvailableStockPriceValue',
  AvailableSalePriceValue = 'AvailableSalePriceValue',
  OpenPurchaseOrdersQuantity = 'OpenPurchaseOrdersQuantity',
  Frequency6Month = 'Frequency6Month',
  Frequency12Month = 'Frequency12Month',
  MonthlyRevenueQuantity = 'MonthlyRevenueQuantity',
  MonthlyRevenueSalePriceValue = 'MonthlyRevenueSalePriceValue',
  SkuCount = 'SkuCount',
}
export interface ProductListConfigurationRequest {
  isEnabled: boolean;
}
export interface ProductListConfigurationResponse {
  /** @format int32 */
  productListId: number;
  /** @format int32 */
  lastProductListVersion: number;
  customerProductListId?: string | null;
  name?: string | null;
  productListSourceTypeId: ProductListSourceType;
  isArchived: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format int32 */
  productCount: number;
  isDeletable: boolean;
  isEnabled?: boolean | null;
  isConfigured: boolean;
}
export interface ProductListConfigurationResponseCollectionResponse {
  messages?: string[] | null;
  data: ProductListConfigurationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface ProductListConfigurationResponseDataResponse {
  messages?: string[] | null;
  data: ProductListConfigurationResponse;
}
export interface ProductListConfigurationUpdateRequest {
  isEnabled: boolean;
}
export interface ProductListItemComparisonInformationResponse {
  productIdentificationTypeId?: IdentificationType | null;
  unidentifiedItems: string[];
  products: ProductResponse[];
}
export interface ProductListItemComparisonInformationResponseDataResponse {
  messages?: string[] | null;
  data: ProductListItemComparisonInformationResponse;
}
export interface ProductListItemsIdentificationResponse {
  productIdentificationTypeId?: IdentificationType | null;
  unidentifiedItems: string[];
  /** @format int32 */
  identifiedItemsCount: number;
}
export interface ProductListItemsIdentificationResponseCollectionResponse {
  messages?: string[] | null;
  data: ProductListItemsIdentificationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface ProductListItemsIdentificationResponseDataResponse {
  messages?: string[] | null;
  data: ProductListItemsIdentificationResponse;
}
export interface ProductListItemsRequest {
  productIdentificationTypeId?: IdentificationType | null;
  productIdentifications?: string[] | null;
}
export interface ProductListRequest {
  name?: string | null;
}
export interface ProductListResponse {
  /** @format int32 */
  productListId: number;
  /** @format int32 */
  lastProductListVersion: number;
  customerProductListId?: string | null;
  name?: string | null;
  productListSourceTypeId: ProductListSourceType;
  isArchived: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format int32 */
  productCount: number;
  isDeletable: boolean;
}
export interface ProductListResponseCollectionResponse {
  messages?: string[] | null;
  data: ProductListResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface ProductListResponseDataResponse {
  messages?: string[] | null;
  data: ProductListResponse;
}
export enum ProductListSourceType {
  Integration = 'Integration',
  Custom = 'Custom',
}
export interface ProductListUpdateRequest {
  name?: string | null;
  isArchived?: boolean | null;
}
export interface ProductOverviewResponse {
  productAttributeValueTypeId: ProductAttributeValueType;
  value: string;
}
export interface ProductOverviewResponseCollectionResponse {
  messages?: string[] | null;
  data: ProductOverviewResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface ProductResponse {
  /** @format int64 */
  id: number;
  customerId?: string | null;
  name: string;
  code: string;
  /** @format int64 */
  categoryId: number;
  allCategoryIds?: number[] | null;
  brand?: BrandResponse | null;
  compareType?: CompareType | null;
  department?: DepartmentResponse | null;
}
export interface ProductResponseCollectionResponse {
  messages?: string[] | null;
  data: ProductResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface RegionFlowGroupRequest {
  /** @minItems 1 */
  regionIds: number[];
}
export interface RegionFlowGroupResponse {
  /** @format int32 */
  id: number;
  regions: RegionResponse[];
}
export interface RegionFlowGroupResponseCollectionResponse {
  messages?: string[] | null;
  data: RegionFlowGroupResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface RegionFlowGroupResponseDataResponse {
  messages?: string[] | null;
  data: RegionFlowGroupResponse;
}
export enum RegionFlowType {
  AllEnabled = 'AllEnabled',
  WithinRegion = 'WithinRegion',
  CustomRedistribution = 'CustomRedistribution',
}
export interface RegionResponse {
  /** @format int32 */
  id: number;
  name: string;
  customerRegionId?: string | null;
}
export interface RegionResponseCollectionResponse {
  messages?: string[] | null;
  data: RegionResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface RoutesEvaluationRatesResponse {
  /** @format int32 */
  totalQuantity: number;
  /** @format int32 */
  executedQuantity: number;
  /** @format double */
  totalValue: number;
  /** @format double */
  executedValue: number;
  /** @format int32 */
  totalSkuCount: number;
  /** @format int32 */
  executedSkuCount: number;
  /** @format int32 */
  totalRouteCount: number;
  /** @format int32 */
  executedRouteCount: number;
}
export interface SaleMonthlyOverviewResponse {
  monthInterval: MonthIntervalResponse;
  overviews: TransactionValueResponse[];
}
export interface SaleMonthlyOverviewResponseCollectionResponse {
  messages?: string[] | null;
  data: SaleMonthlyOverviewResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface ScopeEntityConfigurationResponse {
  /** @format int64 */
  warehouseId: number;
  /** @format int32 */
  departmentId: number;
  isEnabled: boolean;
  useAsSource: boolean;
  useAsTarget: boolean;
  /** @format int32 */
  maxNumberOfSourceStores: number;
  /** @format int32 */
  maxNumberOfTargetStores: number;
  /** @format int32 */
  maxNumberOfSourcePickingPositions: number;
  /** @format int32 */
  minimumRouteValue: number;
  isSuperTarget: boolean;
  isClosing: boolean;
}
export interface ScopeEntityConfigurationResponseCollectionResponse {
  messages?: string[] | null;
  data: ScopeEntityConfigurationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface SkuAttributeResponse {
  type: AttributeDefinitionType;
  value: string;
}
export interface SkuAttributeResponseCollectionResponse {
  messages?: string[] | null;
  data: SkuAttributeResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface SkuBaseResponse {
  /** @format int32 */
  skuId: number;
  /** @format int64 */
  storeId: number;
  /** @format int64 */
  productId: number;
  /** @format int32 */
  skuClassId: number;
  store?: StoreResponse | null;
  product?: ProductResponse | null;
  skuClass?: SkuClassResponse | null;
  /** @format int32 */
  availableSupply: number;
  /** @format double */
  stockPrice: number;
  /** @format double */
  salePrice: number;
  /** @format double */
  vat: number;
  /** @format int32 */
  minimalQuantityForRedistribution: number;
  /** @format int32 */
  minimalSetForRedistribution: number;
  /** @format date-time */
  firstPurchase: string;
  /** @format date-time */
  lastPurchase: string;
  isEnabled: boolean;
  useAsSource?: boolean | null;
  useAsTarget?: boolean | null;
  isForced: boolean;
}
export interface SkuClassConfigurationRequest {
  isEnabled?: boolean | null;
  useAsSource?: boolean | null;
  useAsTarget?: boolean | null;
}
export interface SkuClassConfigurationResponse {
  skuClass?: SkuClassResponse | null;
  isEnabled: boolean;
  useAsSource: boolean;
  useAsTarget: boolean;
}
export interface SkuClassConfigurationResponseCollectionResponse {
  messages?: string[] | null;
  data: SkuClassConfigurationResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface SkuClassConfigurationResponseDataResponse {
  messages?: string[] | null;
  data: SkuClassConfigurationResponse;
}
export interface SkuClassResponse {
  /** @format int32 */
  id: number;
  name: string;
  customerSkuClassId?: string | null;
}
export interface SkuClassResponseCollectionResponse {
  messages?: string[] | null;
  data: SkuClassResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface SkuCombinationResponse {
  /** @format int32 */
  skuId?: number | null;
  product?: ProductResponse | null;
  warehouse?: WarehouseResponse | null;
  compareType: CompareType;
  /** @format int32 */
  quantity: number;
}
export interface SkuFieldConfigurationResponse {
  fieldType: ConfigurationFieldType;
  value: string;
}
export interface SkuForecastResponse {
  computeMethodJson?: string | null;
  additionalAttributesJson?: string | null;
}
export interface SkuRedistributionResponse {
  product: ProductResponse;
  department: DepartmentResponse;
  brand: BrandResponse;
  sourceStore: StoreResponse;
  targetStore: StoreResponse;
  sourceRegion: RegionResponse;
  targetRegion: RegionResponse;
  /** @format int32 */
  sourceSkuId: number;
  sourceSkuTypeId: SkuType;
  targetSkuTypeId: SkuType;
  /** @format int32 */
  totalQuantityFromSourceSku: number;
  /** @format int32 */
  targetSkuId: number;
  /** @format int32 */
  totalQuantityToTargetSku: number;
  /** @format int32 */
  totalAvailableSupplyOfTargetSku: number;
  /** @format int32 */
  quantityProposed: number;
  /** @format int32 */
  quantity: number;
  /** @format double */
  value: number;
  /** @format int32 */
  totalAvailableSupplyOfSourceSku: number;
  isSourceForced: boolean;
  isSourceClosing: boolean;
  isSendToStockout: boolean;
  /** @format int32 */
  totalRouteFromSource: number;
  /** @format int32 */
  totalRouteToTarget: number;
}
export interface SkuRedistributionResponseCollectionResponse {
  messages?: string[] | null;
  data: SkuRedistributionResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface SkuResponse {
  /** @format int32 */
  skuId: number;
  /** @format int64 */
  storeId: number;
  /** @format int64 */
  productId: number;
  /** @format int32 */
  skuClassId: number;
  store?: StoreResponse | null;
  product?: ProductResponse | null;
  skuClass?: SkuClassResponse | null;
  /** @format int32 */
  availableSupply: number;
  /** @format double */
  stockPrice: number;
  /** @format double */
  salePrice: number;
  /** @format double */
  vat: number;
  /** @format int32 */
  minimalQuantityForRedistribution: number;
  /** @format int32 */
  minimalSetForRedistribution: number;
  /** @format date-time */
  firstPurchase: string;
  /** @format date-time */
  lastPurchase: string;
  isEnabled: boolean;
  useAsSource?: boolean | null;
  useAsTarget?: boolean | null;
  isForced: boolean;
  attributes: SkuAttributeResponse[];
}
export interface SkuResponseCollectionResponse {
  messages?: string[] | null;
  data: SkuResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface SkuResponseDataResponse {
  messages?: string[] | null;
  data: SkuResponse;
}
export enum SkuType {
  DeadStock = 'DeadStock',
  SlowMover = 'SlowMover',
  FastMover = 'FastMover',
}
export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}
export interface Sorting {
  fieldName: string;
  direction?: SortDirection;
}
export interface SourceStoreEvaluationRatesResponse {
  store: StoreResponse;
  department?: DepartmentResponse | null;
  evaluationSummary: RoutesEvaluationRatesResponse;
}
export interface SourceStoreEvaluationRatesResponseCollectionResponse {
  messages?: string[] | null;
  data: SourceStoreEvaluationRatesResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
/** Results for a source store. Contains the store, the picking results and the progress status for store. */
export interface SourceStorePickingResultResponse {
  store: StoreResponse;
  department?: DepartmentResponse | null;
  pickingResults: RoutesEvaluationRatesResponse;
  progressStatus: PapProgressStatus;
}
export interface SourceStorePickingResultResponseCollectionResponse {
  messages?: string[] | null;
  data: SourceStorePickingResultResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum StatisticType {
  Minimal = 'Minimal',
  Average = 'Average',
  Median = 'Median',
  Maximum = 'Maximum',
}
export interface StorePickingInsightsResponse {
  /**
   * The number of SKUs picked
   * @format int32
   */
  skuCount: number;
  /**
   * Mean picking time per SKU based on all durations. Not Filterable.
   * @format int32
   */
  meanPickingTimePerSku: number;
  /**
   * Mean picking time per SKU excluding outliers. Not Filterable.
   * @format int32
   */
  trimmedMeanPickingTimePerSku: number;
  /**
   * Total picking time for all SKUs excluding outliers. Not Filterable.
   * @format int32
   */
  trimmedPickingTime: number;
  /**
   * Sum of all durations. Not Filterable.
   * @format int32
   */
  totalPickingTime: number;
  /**
   * Median picking time based on all durations. Not Filterable.
   * @format double
   */
  medianPickingTime: number;
  store: StoreResponse;
  /** @format int32 */
  executedQuantity: number;
  /** @format double */
  executedValue: number;
}
export interface StorePickingInsightsResponseCollectionResponse {
  messages?: string[] | null;
  data: StorePickingInsightsResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface StoreResponse {
  /** @format int64 */
  id: number;
  name: string;
  code?: string | null;
  customerStoreId?: string | null;
  storeType?: StoreTypeResponse | null;
  region?: RegionResponse | null;
  /** @format date-time */
  lastSaleDate?: string | null;
}
export interface StoreResponseCollectionResponse {
  messages?: string[] | null;
  data: StoreResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface StoreTypeResponse {
  /** @format int64 */
  id: number;
  name: string;
  customerWarehouseTypeId?: string | null;
}
export interface StoreTypeResponseCollectionResponse {
  messages?: string[] | null;
  data: StoreTypeResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum SuperTargetRedistributionType {
  ProductsInRedistribution = 'ProductsInRedistribution',
  SourceWarehousesInRedistribution = 'SourceWarehousesInRedistribution',
  AllWarehouses = 'AllWarehouses',
  DoNotUse = 'DoNotUse',
}
export interface TargetListCreateRequest {
  targetListVersionConfigurationTypeId: TargetListVersionConfigurationType;
  /** @minLength 1 */
  name: string;
}
export interface TargetListItemComparisonInformationResponse {
  productIdentificationTypeId: IdentificationType;
  warehouseIdentificationTypeId: IdentificationType;
  unidentifiedProducts: string[];
  unidentifiedWarehouses: string[];
  unidentifiedItems: TargetListItemIdentificationResponse[];
  skuCombination: SkuCombinationResponse[];
}
export interface TargetListItemComparisonInformationResponseDataResponse {
  messages?: string[] | null;
  data: TargetListItemComparisonInformationResponse;
}
export interface TargetListItemIdentificationResponse {
  warehouseIdentification: string;
  productIdentification: string;
  /** @format int32 */
  quantity: number;
}
export interface TargetListItemQuantityRequest {
  warehouseIdentification: string;
  productIdentification: string;
  /** @format int32 */
  quantity: number;
}
export interface TargetListItemResponse {
  /** @format int32 */
  skuId: number;
  product: ProductResponse;
  warehouse: WarehouseResponse;
  /** @format int32 */
  quantity: number;
}
export interface TargetListItemResponseCollectionResponse {
  messages?: string[] | null;
  data: TargetListItemResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface TargetListItemsIdentificationResponse {
  productIdentificationTypeId: IdentificationType;
  warehouseIdentificationTypeId: IdentificationType;
  unidentifiedProducts: string[];
  unidentifiedWarehouses: string[];
  unidentifiedItems: TargetListItemIdentificationResponse[];
  /** @format int32 */
  identifiedItemsCount: number;
  /** @format int32 */
  identifiedProductsCount: number;
  /** @format int32 */
  identifiedWarehousesCount: number;
  targetListVersionConfigurationTypeId: TargetListVersionConfigurationType;
}
export interface TargetListItemsIdentificationResponseDataResponse {
  messages?: string[] | null;
  data: TargetListItemsIdentificationResponse;
}
export interface TargetListItemsRequest {
  targetListVersionConfigurationTypeId?: TargetListVersionConfigurationType | null;
  productIdentificationTypeId?: IdentificationType | null;
  warehouseIdentificationTypeId?: IdentificationType | null;
  targetIdentifications?: TargetListItemQuantityRequest[] | null;
}
export interface TargetListResponse {
  /** @format int32 */
  targetListId: number;
  /** @format int32 */
  lastTargetListVersion: number;
  customerProductListId?: string | null;
  name?: string | null;
  isArchived: boolean;
  description?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format int32 */
  productCount: number;
  /** @format int32 */
  warehouseCount: number;
  /** @format int32 */
  skuCount: number;
  isDeletable: boolean;
  isArchivable: boolean;
  targetListVersionConfigurationTypeId: TargetListVersionConfigurationType;
}
export interface TargetListResponseCollectionResponse {
  messages?: string[] | null;
  data: TargetListResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface TargetListResponseDataResponse {
  messages?: string[] | null;
  data: TargetListResponse;
}
export interface TargetListSkuResponse {
  /** @format int32 */
  targetListId: number;
  /** @format int32 */
  version: number;
  name?: string | null;
  targetListVersionConfigurationTypeId: TargetListVersionConfigurationType;
  /** @format int32 */
  quantity?: number | null;
}
export interface TargetListUpdateRequest {
  name?: string | null;
  isArchived?: boolean | null;
}
export enum TargetListVersionConfigurationType {
  NotApplicable = 'NotApplicable',
  ReceiveDefinedQuantity = 'ReceiveDefinedQuantity',
  TopUpDefinedQuantity = 'TopUpDefinedQuantity',
}
export interface TargetStoreEvaluationRatesResponse {
  store: StoreResponse;
  department?: DepartmentResponse | null;
  evaluationSummary: EvaluationRatesResponse;
}
export interface TargetStoreEvaluationRatesResponseCollectionResponse {
  messages?: string[] | null;
  data: TargetStoreEvaluationRatesResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
/** Results for a target store related to a source store. Contains the store, the picking results and the progress status for store. */
export interface TargetStorePickingResultResponse {
  store: StoreResponse;
  department?: DepartmentResponse | null;
  pickingResults: EvaluationRatesResponse;
  progressStatus: PapProgressStatus;
}
export interface TargetStorePickingResultResponseCollectionResponse {
  messages?: string[] | null;
  data: TargetStorePickingResultResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface TemplateRequest {
  title?: string | null;
  description?: string | null;
}
export interface TemplateResponse {
  /** @format int32 */
  id: number;
  status: CalculationStatus;
  calculationFlags: CalculationFlags;
  title: string;
  description?: string | null;
  /** @format date-time */
  created: string;
  /** @format date */
  applicationDate: string;
  owner?: UserResponse | null;
  /** @format int64 */
  rootCategoryId: number;
  overlappingCalculations?: CalculationResponse[] | null;
}
export interface TemplateResponseCollectionResponse {
  messages?: string[] | null;
  data: TemplateResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface TemplateResponseDataResponse {
  messages?: string[] | null;
  data: TemplateResponse;
}
export interface TemplateUpdateRequest {
  title?: string | null;
  description?: string | null;
}
export interface TransactionMonthlyOverviewResponse {
  /**
   * @deprecated
   * @format int32
   */
  year: number;
  /**
   * @deprecated
   * @format int32
   */
  month: number;
  monthInterval: MonthIntervalResponse;
  transactionValues: TransactionValueResponse[];
}
export interface TransactionMonthlyOverviewResponseCollectionResponse {
  messages?: string[] | null;
  data: TransactionMonthlyOverviewResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export enum TransactionOverviewType {
  SaleRegular = 'SaleRegular',
  SalePromo = 'SalePromo',
  SaleSellout = 'SaleSellout',
  ForecastMin = 'ForecastMin',
  ForecastMax = 'ForecastMax',
}
export interface TransactionValueResponse {
  overviewValueType: OverviewValueType;
  transactionOverviewType: TransactionOverviewType;
  /** @format double */
  value: number;
}
export interface UserResponse {
  id: string;
  fullName: string;
  email: string;
  isSuperUser: boolean;
  isConfirmed: boolean;
  isActive: boolean;
}
export interface WarehouseResponse {
  /** @format int64 */
  id: number;
  name: string;
  code?: string | null;
  customerWarehouseId?: string | null;
  warehouseType?: WarehouseTypeResponse | null;
  region?: RegionResponse | null;
  /** @format date-time */
  lastSaleDate?: string | null;
}
export interface WarehouseResponseCollectionResponse {
  messages?: string[] | null;
  data: WarehouseResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
export interface WarehouseTypeResponse {
  /** @format int64 */
  id: number;
  name: string;
  customerWarehouseTypeId?: string | null;
}
export interface WarehouseTypeResponseCollectionResponse {
  messages?: string[] | null;
  data: WarehouseTypeResponse[];
  /** @format int32 */
  totalCount?: number | null;
}
