/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  BrandConfigurationResponseCollectionResponse,
  CalculationConfigurationResponseDataResponse,
  CalculationDepartmentResponseCollectionResponse,
  CalculationDetailResponseDataResponse,
  CalculationEvaluationRatesSummaryResponseDataResponse,
  CalculationExperimentalConfigurationResponseCollectionResponse,
  CalculationFileResponseCollectionResponse,
  CalculationPickingInsightsSummaryResponseDataResponse,
  CalculationPickingIssuesResponseCollectionResponse,
  CalculationPickingResponseDataResponse,
  CalculationRequest,
  CalculationSkuResponseDataResponse,
  CalculationStatisticClusterType,
  CalculationStatisticRegionResponseCollectionResponse,
  CalculationStatisticRouteResponseCollectionResponse,
  CalculationStatisticRouteSummaryResponseCollectionResponse,
  CalculationStatisticStoreSourceResponseCollectionResponse,
  CalculationStatisticStoreSummaryResponseDataResponse,
  CalculationStatisticStoreTargetResponseCollectionResponse,
  CalculationStatisticSummaryResponseDataResponse,
  CalculationStatisticTargetListItemResponseCollectionResponse,
  CalculationStatisticTargetListItemSourceResponseCollectionResponse,
  CalculationStatisticTargetListSummaryResponseDataResponse,
  CalculationUpdateRequest,
  CalculationWithRelatedEntitiesResponseCollectionResponse,
  CategoryEvaluationRatesResponseCollectionResponse,
  CategoryFieldConfigurationResponseCollectionResponse,
  CategoryInsightsResponseDataResponse,
  CategorySkuCountResponseCollectionResponse,
  CategoryWarehouseOverviewResponseCollectionResponse,
  Condition,
  ConfigurationRuleResponseCollectionResponse,
  ForecastMonthlyOverviewResponseCollectionResponse,
  PickingIssueResponseCollectionResponse,
  ProductListConfigurationResponseCollectionResponse,
  RegionFlowGroupResponseCollectionResponse,
  RegionResponseCollectionResponse,
  SaleMonthlyOverviewResponseCollectionResponse,
  ScopeEntityConfigurationResponseCollectionResponse,
  SkuAttributeResponseCollectionResponse,
  SkuClassConfigurationResponseCollectionResponse,
  SkuRedistributionResponseCollectionResponse,
  Sorting,
  SourceStoreEvaluationRatesResponseCollectionResponse,
  SourceStorePickingResultResponseCollectionResponse,
  StorePickingInsightsResponseCollectionResponse,
  StoreResponseCollectionResponse,
  StoreTypeResponseCollectionResponse,
  TargetListResponseCollectionResponse,
  TargetStoreEvaluationRatesResponseCollectionResponse,
  TargetStorePickingResultResponseCollectionResponse,
  TransactionMonthlyOverviewResponseCollectionResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class Calculations<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculations
   * @request GET:/Calculations
   * @response `200` `CalculationWithRelatedEntitiesResponseCollectionResponse` OK
   */
  getCalculations = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationWithRelatedEntitiesResponseCollectionResponse, any>({
      path: `/Calculations`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name CalculationsCreate
   * @request POST:/Calculations
   * @response `200` `void` OK
   */
  calculationsCreate = (data: CalculationRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Calculations`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculation
   * @request GET:/Calculations/{id}
   * @response `200` `CalculationDetailResponseDataResponse` OK
   */
  getCalculation = (id: number, params: RequestParams = {}) =>
    this.http.request<CalculationDetailResponseDataResponse, any>({
      path: `/Calculations/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name PatchCalculation
   * @request PATCH:/Calculations/{id}
   * @response `200` `CalculationDetailResponseDataResponse` OK
   */
  patchCalculation = (id: number, data: CalculationUpdateRequest, params: RequestParams = {}) =>
    this.http.request<CalculationDetailResponseDataResponse, any>({
      path: `/Calculations/${id}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name DeleteCalculation
   * @request DELETE:/Calculations/{id}
   * @response `200` `void` OK
   */
  deleteCalculation = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Calculations/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name PatchCalculationPairings
   * @request PATCH:/Calculations/{id}/Pairings
   * @response `200` `void` OK
   */
  patchCalculationPairings = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Calculations/${id}/Pairings`,
      method: 'PATCH',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationBrands
   * @request GET:/Calculations/{id}/Brands
   * @response `200` `BrandConfigurationResponseCollectionResponse` OK
   */
  getCalculationBrands = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<BrandConfigurationResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Brands`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationCategories
   * @request GET:/Calculations/{id}/Categories
   * @response `200` `CategorySkuCountResponseCollectionResponse` OK
   */
  getCalculationCategories = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CategorySkuCountResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Categories`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationCategoryConfigurations
   * @request GET:/Calculations/{id}/Categories/{categoryId}/Configurations
   * @response `200` `CategoryFieldConfigurationResponseCollectionResponse` OK
   */
  getCalculationCategoryConfigurations = (
    id: number,
    categoryId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<CategoryFieldConfigurationResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Categories/${categoryId}/Configurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationCategoryInsights
   * @request GET:/Calculations/{id}/Categories/{categoryId}/Insights
   * @response `200` `CategoryInsightsResponseDataResponse` OK
   */
  getCalculationCategoryInsights = (id: number, categoryId: number, params: RequestParams = {}) =>
    this.http.request<CategoryInsightsResponseDataResponse, any>({
      path: `/Calculations/${id}/Categories/${categoryId}/Insights`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationCategoryPairings
   * @request GET:/Calculations/{id}/Categories/{categoryId}/Pairings
   * @response `200` `SkuRedistributionResponseCollectionResponse` OK
   */
  getCalculationCategoryPairings = (
    id: number,
    categoryId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SkuRedistributionResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Categories/${categoryId}/Pairings`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationCategoryWarehouseOverview
   * @request GET:/Calculations/{id}/Categories/{categoryId}/WarehouseOverviews
   * @deprecated
   * @response `200` `CategoryWarehouseOverviewResponseCollectionResponse` OK
   */
  getCalculationCategoryWarehouseOverview = (
    id: number,
    categoryId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<CategoryWarehouseOverviewResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Categories/${categoryId}/WarehouseOverviews`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationConfigurations
   * @request GET:/Calculations/{id}/Configurations
   * @response `200` `CalculationConfigurationResponseDataResponse` OK
   */
  getCalculationConfigurations = (id: number, params: RequestParams = {}) =>
    this.http.request<CalculationConfigurationResponseDataResponse, any>({
      path: `/Calculations/${id}/Configurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationExperimentalConfigurations
   * @request GET:/Calculations/{id}/ExperimentalConfigurations
   * @response `200` `CalculationExperimentalConfigurationResponseCollectionResponse` OK
   */
  getCalculationExperimentalConfigurations = (id: number, params: RequestParams = {}) =>
    this.http.request<CalculationExperimentalConfigurationResponseCollectionResponse, any>({
      path: `/Calculations/${id}/ExperimentalConfigurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationDeviations
   * @request GET:/Calculations/{id}/Deviations
   * @response `200` `CategoryFieldConfigurationResponseCollectionResponse` OK
   */
  getCalculationDeviations = (id: number, params: RequestParams = {}) =>
    this.http.request<CategoryFieldConfigurationResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Deviations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationProductListsV2
   * @request GET:/Calculations/{id}/ProductLists/V2
   * @response `200` `ProductListConfigurationResponseCollectionResponse` OK
   */
  getCalculationProductListsV2 = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductListConfigurationResponseCollectionResponse, any>({
      path: `/Calculations/${id}/ProductLists/V2`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationFiles
   * @request GET:/Calculations/{calculationId}/Files
   * @response `200` `CalculationFileResponseCollectionResponse` OK
   */
  getCalculationFiles = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationFileResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Files`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationFile
   * @request GET:/Calculations/{calculationId}/Files/{id}
   * @response `200` `CalculationFileResponseCollectionResponse` OK
   */
  getCalculationFile = (calculationId: number, id: number, params: RequestParams = {}) =>
    this.http.request<CalculationFileResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Files/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationRegions
   * @request GET:/Calculations/{calculationId}/Regions
   * @response `200` `RegionResponseCollectionResponse` OK
   */
  getCalculationRegions = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RegionResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Regions`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationSkuClassConfigurations
   * @request GET:/Calculations/{id}/SkuClasses/Configurations
   * @response `200` `SkuClassConfigurationResponseCollectionResponse` OK
   */
  getCalculationSkuClassConfigurations = (id: number, params: RequestParams = {}) =>
    this.http.request<SkuClassConfigurationResponseCollectionResponse, any>({
      path: `/Calculations/${id}/SkuClasses/Configurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationSkus
   * @request GET:/Calculations/{id}/Skus/{skuId}
   * @response `200` `CalculationSkuResponseDataResponse` OK
   */
  getCalculationSkus = (id: number, skuId: number, params: RequestParams = {}) =>
    this.http.request<CalculationSkuResponseDataResponse, any>({
      path: `/Calculations/${id}/Skus/${skuId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationSkuAttributes
   * @request GET:/Calculations/{id}/Skus/{skuId}/Attributes
   * @response `200` `SkuAttributeResponseCollectionResponse` OK
   */
  getCalculationSkuAttributes = (id: number, skuId: number, params: RequestParams = {}) =>
    this.http.request<SkuAttributeResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Skus/${skuId}/Attributes`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationSkuMonthlyTransaction
   * @request GET:/Calculations/{id}/Skus/{skuId}/MonthlyTransactions
   * @deprecated
   * @response `200` `TransactionMonthlyOverviewResponseCollectionResponse` OK
   */
  getCalculationSkuMonthlyTransaction = (id: number, skuId: number, params: RequestParams = {}) =>
    this.http.request<TransactionMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Skus/${skuId}/MonthlyTransactions`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationSkuMonthlySaleWithMonthsRange
   * @request GET:/Calculations/{id}/Skus/{skuId}/MonthlySales/MonthsRange/{monthsRange}
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getCalculationSkuMonthlySaleWithMonthsRange = (
    id: number,
    skuId: number,
    monthsRange: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Skus/${skuId}/MonthlySales/MonthsRange/${monthsRange}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationSkuMonthlyForecast
   * @request GET:/Calculations/{id}/Skus/{skuId}/MonthlyForecasts
   * @response `200` `ForecastMonthlyOverviewResponseCollectionResponse` OK
   */
  getCalculationSkuMonthlyForecast = (id: number, skuId: number, params: RequestParams = {}) =>
    this.http.request<ForecastMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Calculations/${id}/Skus/${skuId}/MonthlyForecasts`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatistics
   * @request GET:/Calculations/{calculationId}/Statistics
   * @response `200` `CalculationStatisticSummaryResponseDataResponse` OK
   */
  getCalculationStatistics = (
    calculationId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}
   * @response `200` `CalculationStatisticSummaryResponseDataResponse` OK
   */
  getCalculationStatisticsByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticRouteSummaries
   * @request GET:/Calculations/{calculationId}/Statistics/RouteSummaries
   * @response `200` `CalculationStatisticRouteSummaryResponseCollectionResponse` OK
   */
  getCalculationStatisticRouteSummaries = (
    calculationId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticRouteSummaryResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/RouteSummaries`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticRouteSummariesByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/RouteSummaries
   * @response `200` `CalculationStatisticRouteSummaryResponseCollectionResponse` OK
   */
  getCalculationStatisticRouteSummariesByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticRouteSummaryResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/RouteSummaries`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticRoutes
   * @request GET:/Calculations/{calculationId}/Statistics/Routes
   * @response `200` `CalculationStatisticRouteResponseCollectionResponse` OK
   */
  getCalculationStatisticRoutes = (
    calculationId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticRouteResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Routes`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticRoutesByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/Routes
   * @response `200` `CalculationStatisticRouteResponseCollectionResponse` OK
   */
  getCalculationStatisticRoutesByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticRouteResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/Routes`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticSourceStoreSummaries
   * @request GET:/Calculations/{calculationId}/Statistics/StoreSummaries
   * @response `200` `CalculationStatisticStoreSummaryResponseDataResponse` OK
   */
  getCalculationStatisticSourceStoreSummaries = (
    calculationId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticStoreSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/StoreSummaries`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticSourceStoreSummariesByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/StoreSummaries
   * @response `200` `CalculationStatisticStoreSummaryResponseDataResponse` OK
   */
  getCalculationStatisticSourceStoreSummariesByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticStoreSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/StoreSummaries`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticSourceStores
   * @request GET:/Calculations/{calculationId}/Statistics/SourceStores
   * @response `200` `CalculationStatisticStoreSourceResponseCollectionResponse` OK
   */
  getCalculationStatisticSourceStores = (
    calculationId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticStoreSourceResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/SourceStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticSourceStoresByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/SourceStores
   * @response `200` `CalculationStatisticStoreSourceResponseCollectionResponse` OK
   */
  getCalculationStatisticSourceStoresByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticStoreSourceResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/SourceStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticTargetStores
   * @request GET:/Calculations/{calculationId}/Statistics/TargetStores
   * @response `200` `CalculationStatisticStoreTargetResponseCollectionResponse` OK
   */
  getCalculationStatisticTargetStores = (
    calculationId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticStoreTargetResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/TargetStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticTargetStoresByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/TargetStores
   * @response `200` `CalculationStatisticStoreTargetResponseCollectionResponse` OK
   */
  getCalculationStatisticTargetStoresByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticStoreTargetResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/TargetStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticRegions
   * @request GET:/Calculations/{calculationId}/Statistics/Regions
   * @response `200` `CalculationStatisticRegionResponseCollectionResponse` OK
   */
  getCalculationStatisticRegions = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticRegionResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Regions`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticRegionsByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/Regions
   * @response `200` `CalculationStatisticRegionResponseCollectionResponse` OK
   */
  getCalculationStatisticRegionsByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticRegionResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/Regions`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticTargetListSummary
   * @request GET:/Calculations/{calculationId}/Statistics/TargetListSummary
   * @response `200` `CalculationStatisticTargetListSummaryResponseDataResponse` OK
   */
  getCalculationStatisticTargetListSummary = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<CalculationStatisticTargetListSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/TargetListSummary`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticTargetListItems
   * @request GET:/Calculations/{calculationId}/Statistics/TargetListItems
   * @response `200` `CalculationStatisticTargetListItemResponseCollectionResponse` OK
   */
  getCalculationStatisticTargetListItems = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticTargetListItemResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/TargetListItems`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticTargetListItemSources
   * @request GET:/Calculations/{calculationId}/Statistics/TargetListItems/{targetSkuId}/Sources
   * @response `200` `CalculationStatisticTargetListItemSourceResponseCollectionResponse` OK
   */
  getCalculationStatisticTargetListItemSources = (
    calculationId: number,
    targetSkuId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationStatisticTargetListItemSourceResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/TargetListItems/${targetSkuId}/Sources`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsEvaluationRates
   * @request GET:/Calculations/{calculationId}/Statistics/EvaluationRates
   * @response `200` `CalculationEvaluationRatesSummaryResponseDataResponse` OK
   */
  getCalculationStatisticsEvaluationRates = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<CalculationEvaluationRatesSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/EvaluationRates`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsExecutionRatesByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/EvaluationRates
   * @response `200` `CalculationEvaluationRatesSummaryResponseDataResponse` OK
   */
  getCalculationStatisticsExecutionRatesByDepartment = (
    calculationId: number,
    departmentId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationEvaluationRatesSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/EvaluationRates`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsSourceStoresEvaluationRates
   * @request GET:/Calculations/{calculationId}/Statistics/EvaluationRates/SourceStores
   * @response `200` `SourceStoreEvaluationRatesResponseCollectionResponse` OK
   */
  getCalculationStatisticsSourceStoresEvaluationRates = (
    calculationId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SourceStoreEvaluationRatesResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/EvaluationRates/SourceStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsSourceStoresEvaluationRatesByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/EvaluationRates/SourceStores
   * @response `200` `SourceStoreEvaluationRatesResponseCollectionResponse` OK
   */
  getCalculationStatisticsSourceStoresEvaluationRatesByDepartment = (
    calculationId: number,
    departmentId: number,
    query?: {
      /** @default "Store" */
      clusterType?: CalculationStatisticClusterType;
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SourceStoreEvaluationRatesResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/EvaluationRates/SourceStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsSourceStoreTargetStoresExecutionRates
   * @request GET:/Calculations/{calculationId}/Statistics/EvaluationRates/SourceStores/{sourceStoreId}/TargetStores
   * @response `200` `TargetStoreEvaluationRatesResponseCollectionResponse` OK
   */
  getCalculationStatisticsSourceStoreTargetStoresExecutionRates = (
    calculationId: number,
    sourceStoreId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TargetStoreEvaluationRatesResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/EvaluationRates/SourceStores/${sourceStoreId}/TargetStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsSourceStoreTargetStoresExecutionRatesByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/EvaluationRates/SourceStores/{sourceStoreId}/TargetStores
   * @response `200` `TargetStoreEvaluationRatesResponseCollectionResponse` OK
   */
  getCalculationStatisticsSourceStoreTargetStoresExecutionRatesByDepartment = (
    calculationId: number,
    departmentId: number,
    sourceStoreId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TargetStoreEvaluationRatesResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/EvaluationRates/SourceStores/${sourceStoreId}/TargetStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsSourceStoreCategoriesExecutionRates
   * @request GET:/Calculations/{calculationId}/Statistics/EvaluationRates/SourceStores/{storeId}/Categories
   * @response `200` `CategoryEvaluationRatesResponseCollectionResponse` OK
   */
  getCalculationStatisticsSourceStoreCategoriesExecutionRates = (
    calculationId: number,
    storeId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CategoryEvaluationRatesResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/EvaluationRates/SourceStores/${storeId}/Categories`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStatisticsSourceStoreCategoriesExecutionRatesByDepartment
   * @request GET:/Calculations/{calculationId}/Statistics/Departments/{departmentId}/EvaluationRates/SourceStores/{storeId}/Categories
   * @response `200` `CategoryEvaluationRatesResponseCollectionResponse` OK
   */
  getCalculationStatisticsSourceStoreCategoriesExecutionRatesByDepartment = (
    calculationId: number,
    departmentId: number,
    storeId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CategoryEvaluationRatesResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Departments/${departmentId}/EvaluationRates/SourceStores/${storeId}/Categories`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationPicking
   * @summary Overall picking results and status for a calculation.
   * @request GET:/Calculations/{calculationId}/Statistics/Picking
   * @response `200` `CalculationPickingResponseDataResponse` OK
   */
  getCalculationPicking = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<CalculationPickingResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationPickingSourceStores
   * @summary Picking results of each of the source stores for a given calculation.
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/SourceStores
   * @response `200` `SourceStorePickingResultResponseCollectionResponse` OK
   */
  getCalculationPickingSourceStores = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SourceStorePickingResultResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/SourceStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationPickingTargetStores
   * @summary Picking results of each of the target stores for a given source store.
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/SourceStores/{sourceStoreId}/TargetStores
   * @response `200` `TargetStorePickingResultResponseCollectionResponse` OK
   */
  getCalculationPickingTargetStores = (
    calculationId: number,
    sourceStoreId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TargetStorePickingResultResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/SourceStores/${sourceStoreId}/TargetStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationPickingInsightsSummary
   * @summary Statistical data about the picking process for the whole calculation
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/Insights
   * @response `200` `CalculationPickingInsightsSummaryResponseDataResponse` OK
   */
  getCalculationPickingInsightsSummary = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<CalculationPickingInsightsSummaryResponseDataResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/Insights`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationPickingInsights
   * @summary Statistical data about the picking process of source stores like the average picking time per SKU
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/Insights/SourceStores
   * @response `200` `StorePickingInsightsResponseCollectionResponse` OK
   */
  getCalculationPickingInsights = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<StorePickingInsightsResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/Insights/SourceStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetSourceStorePickingInsights
   * @summary Statistical data about the picking process of a source store for each target store
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/Insights/SourceStores/{sourceStoreId}
   * @response `200` `StorePickingInsightsResponseCollectionResponse` OK
   */
  getSourceStorePickingInsights = (
    calculationId: number,
    sourceStoreId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<StorePickingInsightsResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/Insights/SourceStores/${sourceStoreId}`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationPickingIssues
   * @summary Picking issues for a calculation for each source store
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/Issues
   * @response `200` `CalculationPickingIssuesResponseCollectionResponse` OK
   */
  getCalculationPickingIssues = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<CalculationPickingIssuesResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/Issues`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetSkusWithIssues
   * @summary Picking issues for all source stores
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/Issues/SourceStores
   * @response `200` `PickingIssueResponseCollectionResponse` OK
   */
  getSkusWithIssues = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PickingIssueResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/Issues/SourceStores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetSkusWithIssuesForSourceStore
   * @summary Picking issues for a source store
   * @request GET:/Calculations/{calculationId}/Statistics/Picking/Issues/SourceStores/{sourceStoreId}
   * @response `200` `PickingIssueResponseCollectionResponse` OK
   */
  getSkusWithIssuesForSourceStore = (
    calculationId: number,
    sourceStoreId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PickingIssueResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Statistics/Picking/Issues/SourceStores/${sourceStoreId}`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetRegionFlowGroups
   * @request GET:/Calculations/{id}/RegionFlowGroups
   * @response `200` `RegionFlowGroupResponseCollectionResponse` OK
   */
  getRegionFlowGroups = (id: number, params: RequestParams = {}) =>
    this.http.request<RegionFlowGroupResponseCollectionResponse, any>({
      path: `/Calculations/${id}/RegionFlowGroups`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationTargetLists
   * @request GET:/Calculations/{id}/TargetLists
   * @response `200` `TargetListResponseCollectionResponse` OK
   */
  getCalculationTargetLists = (id: number, params: RequestParams = {}) =>
    this.http.request<TargetListResponseCollectionResponse, any>({
      path: `/Calculations/${id}/TargetLists`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStores
   * @request GET:/Calculations/{calculationId}/Stores
   * @response `200` `StoreResponseCollectionResponse` OK
   */
  getCalculationStores = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<StoreResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Stores`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationStoreTypes
   * @request GET:/Calculations/{calculationId}/StoreTypes
   * @response `200` `StoreTypeResponseCollectionResponse` OK
   */
  getCalculationStoreTypes = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<StoreTypeResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/StoreTypes`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationConfigurationRules
   * @request GET:/Calculations/{calculationId}/ConfigurationRules
   * @response `200` `ConfigurationRuleResponseCollectionResponse` OK
   */
  getCalculationConfigurationRules = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<ConfigurationRuleResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/ConfigurationRules`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationScopeEntityConfigurations
   * @request GET:/Calculations/{calculationId}/ConfigurationRules/ScopeEntityConfigurations
   * @response `200` `ScopeEntityConfigurationResponseCollectionResponse` OK
   */
  getCalculationScopeEntityConfigurations = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<ScopeEntityConfigurationResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/ConfigurationRules/ScopeEntityConfigurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Calculations
   * @name GetCalculationDepartments
   * @request GET:/Calculations/{calculationId}/Departments
   * @response `200` `CalculationDepartmentResponseCollectionResponse` OK
   */
  getCalculationDepartments = (
    calculationId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationDepartmentResponseCollectionResponse, any>({
      path: `/Calculations/${calculationId}/Departments`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
}
