/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  CategoryResponseCollectionResponse,
  Condition,
  ProductOverviewResponseCollectionResponse,
  ProductResponseCollectionResponse,
  SaleMonthlyOverviewResponseCollectionResponse,
  Sorting,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';
export class Categories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Categories
   * @name CategoriesList
   * @request GET:/Categories
   * @response `200` `CategoryResponseCollectionResponse` OK
   */
  categoriesList = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CategoryResponseCollectionResponse, any>({
      path: `/Categories`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetCategoryProducts
   * @request GET:/Categories/{id}/Products
   * @response `200` `ProductResponseCollectionResponse` OK
   */
  getCategoryProducts = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductResponseCollectionResponse, any>({
      path: `/Categories/${id}/Products`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetCategoryMonthlySales
   * @request GET:/Categories/{id}/MonthlySales
   * @deprecated
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getCategoryMonthlySales = (id: number, params: RequestParams = {}) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Categories/${id}/MonthlySales`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetCategoryMonthlySalesWithMonthsRange
   * @request GET:/Categories/{id}/MonthlySales/MonthsRange/{monthsRange}
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getCategoryMonthlySalesWithMonthsRange = (
    id: number,
    monthsRange: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Categories/${id}/MonthlySales/MonthsRange/${monthsRange}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetWarehouseCategoryMonthlySales
   * @request GET:/Categories/{id}/Warehouses/{warehouseId}/MonthlySales
   * @deprecated
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getWarehouseCategoryMonthlySales = (
    id: number,
    warehouseId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Categories/${id}/Warehouses/${warehouseId}/MonthlySales`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetWarehouseCategoryMonthlySalesWithMonthsRange
   * @request GET:/Categories/{id}/Warehouses/{warehouseId}/MonthlySales/MonthsRange/{monthsRange}
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getWarehouseCategoryMonthlySalesWithMonthsRange = (
    id: number,
    warehouseId: number,
    monthsRange: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Categories/${id}/Warehouses/${warehouseId}/MonthlySales/MonthsRange/${monthsRange}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetRegionCategoryMonthlySales
   * @request GET:/Categories/{id}/Regions/{regionId}/MonthlySales
   * @deprecated
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getRegionCategoryMonthlySales = (id: number, regionId: number, params: RequestParams = {}) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Categories/${id}/Regions/${regionId}/MonthlySales`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetRegionCategoryMonthlySalesWithMonthsRange
   * @request GET:/Categories/{id}/Regions/{regionId}/MonthlySales/MonthsRange/{monthsRange}
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getRegionCategoryMonthlySalesWithMonthsRange = (
    id: number,
    regionId: number,
    monthsRange: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Categories/${id}/Regions/${regionId}/MonthlySales/MonthsRange/${monthsRange}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name CategoryOverviews
   * @request GET:/Categories/{id}/Overviews
   * @response `200` `ProductOverviewResponseCollectionResponse` OK
   */
  categoryOverviews = (id: number, params: RequestParams = {}) =>
    this.http.request<ProductOverviewResponseCollectionResponse, any>({
      path: `/Categories/${id}/Overviews`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
