/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  CalculationWithOverviewResponseCollectionResponse,
  Condition,
  EntityListSkuResponseCollectionResponse,
  ForecastMonthlyOverviewResponseCollectionResponse,
  SaleMonthlyOverviewResponseCollectionResponse,
  SkuAttributeResponseCollectionResponse,
  SkuResponseCollectionResponse,
  SkuResponseDataResponse,
  Sorting,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';
export class Skus<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Skus
   * @name SkusList
   * @request GET:/Skus
   * @response `200` `SkuResponseCollectionResponse` OK
   */
  skusList = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SkuResponseCollectionResponse, any>({
      path: `/Skus`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSku
   * @request GET:/Skus/{id}
   * @response `200` `SkuResponseDataResponse` OK
   */
  getSku = (id: number, params: RequestParams = {}) =>
    this.http.request<SkuResponseDataResponse, any>({
      path: `/Skus/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSkuAttributes
   * @request GET:/Skus/{id}/Attributes
   * @response `200` `SkuAttributeResponseCollectionResponse` OK
   */
  getSkuAttributes = (id: number, params: RequestParams = {}) =>
    this.http.request<SkuAttributeResponseCollectionResponse, any>({
      path: `/Skus/${id}/Attributes`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSkuEntityLists
   * @request GET:/Skus/{id}/EntityLists
   * @response `200` `EntityListSkuResponseCollectionResponse` OK
   */
  getSkuEntityLists = (id: number, params: RequestParams = {}) =>
    this.http.request<EntityListSkuResponseCollectionResponse, any>({
      path: `/Skus/${id}/EntityLists`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSkuCalculationsBySource
   * @request GET:/Skus/{id}/BySourceCalculations
   * @response `200` `CalculationWithOverviewResponseCollectionResponse` OK
   */
  getSkuCalculationsBySource = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationWithOverviewResponseCollectionResponse, any>({
      path: `/Skus/${id}/BySourceCalculations`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSkuCalculationsByTarget
   * @request GET:/Skus/{id}/ByTargetCalculations
   * @response `200` `CalculationWithOverviewResponseCollectionResponse` OK
   */
  getSkuCalculationsByTarget = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationWithOverviewResponseCollectionResponse, any>({
      path: `/Skus/${id}/ByTargetCalculations`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSkuMonthlySale
   * @request GET:/Skus/{id}/MonthlySales
   * @deprecated
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getSkuMonthlySale = (id: number, params: RequestParams = {}) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Skus/${id}/MonthlySales`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSkuMonthlySaleWithMonthsRange
   * @request GET:/Skus/{id}/MonthlySales/MonthsRange/{monthsRange}
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` OK
   */
  getSkuMonthlySaleWithMonthsRange = (
    id: number,
    monthsRange: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Skus/${id}/MonthlySales/MonthsRange/${monthsRange}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Skus
   * @name GetSkuMonthlyForecast
   * @request GET:/Skus/{id}/MonthlyForecasts
   * @response `200` `ForecastMonthlyOverviewResponseCollectionResponse` OK
   */
  getSkuMonthlyForecast = (id: number, params: RequestParams = {}) =>
    this.http.request<ForecastMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Skus/${id}/MonthlyForecasts`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
